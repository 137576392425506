import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { getCountriesList } from '@/application/utils/countries'
import { trimValues } from '@/application/utils/trim.js'
import { sendInviteApiRequest } from '@/application/services/invite.js'
import { getLocalizedServerError } from '@/application/utils/localization'
import { router } from '@/application/router'

export function useInviteSendForm() {
  const i18n = useI18n()
  const isDisabled = ref(false)
  const errorMessage = ref('')

  const formSchema = computed(() => ({
    email: {
      label: i18n.t('forms.checkout.email'),
      name: 'email',
      as: 'input',
      type: 'text',
      rules: 'required|email|disposable_email',
      cols: ' col-12',
      inputClass: 'form-control',
    },
    name: {
      label: i18n.t('forms.checkout.name'),
      name: 'first_name',
      as: 'input',
      type: 'text',
      rules: '',
      cols: ' col-12 col-sm-6',
      inputClass: 'form-control',
      description: i18n.t('optional'),
    },
    lastName: {
      label: i18n.t('forms.checkout.last_name'),
      name: 'last_name',
      as: 'input',
      type: 'text',
      rules: '',
      cols: ' col-12  col-sm-6',
      inputClass: 'form-control',
      description: i18n.t('optional'),
    },
    phoneNumber: {
      label: i18n.t('phoneNumber'),
      name: 'phone_number',
      type: 'text',
      as: 'input',
      rules: 'phone_mobile',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: `${i18n.t('optional')}<br />${i18n.t('forms.checkout.phone_description')}`,
      isHTMLDescription: true,
    },
    country: {
      label: i18n.t('forms.checkout.country'),
      name: 'country',
      type: 'text',
      as: 'select',
      rules: '',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      children: getCountriesList().map(country => ({
        tag: 'option',
        value: country.code,
        text: country.name,
      })),
      description: i18n.t('optional'),
    },
    postalCode: {
      label: i18n.t('forms.checkout.postalCode'),
      name: 'postal_code',
      type: 'text',
      as: 'input',
      rules: '',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: `${i18n.t('optional')}<br />${i18n.t('forms.checkout.postalCode_description')}`,
      isHTMLDescription: true,
    },
    companyName: {
      label: i18n.t('forms.checkout.companyName'),
      name: 'company_name',
      type: 'text',
      as: 'input',
      rules: '',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('optional'),
    },
    companyWebSite: {
      label: i18n.t('forms.checkout.companyWebSite'),
      name: 'company_website',
      type: 'text',
      as: 'input',
      rules: 'url:false',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('optional'),
    },
    isSendEmail: {
      htmlLabel: i18n.t('forms.invite.fields.isSendEmail'),
      name: 'is_send_email',
      as: 'input',
      type: 'checkbox',
      rules: '',
      cols: 'col-12',
      labelClass: 'cr',
      fieldClass: 'checkbox checkbox-primary d-inline',
      value: true,
      unckeckedValue: false,
    },
  }))
  const submitButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mb-3 mt-4',
    text: i18n.t('forms.invite.submit'),
  }))
  const submit = async(data) => {
    isDisabled.value = true
    errorMessage.value = ''
    const formatted = trimValues(data)
    sendInviteApiRequest(formatted)
      .then(() => {
        isDisabled.value = false
        router.push('invited')
      })
      .catch(err => {
        errorMessage.value = getLocalizedServerError(i18n, 'forms.invite.errors.', err.response)
        isDisabled.value = false
      })
  }

  return {
    formSchema,
    submitButton,
    submit,
    isDisabled,
    errorMessage,
  }
}
