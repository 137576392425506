import axios from 'axios'
import { interceptor } from '@/application/utils/interceptor'

const domain = process.env.VUE_APP_API_PATH

const sendInviteApi = `${domain}api/v1/dashboard/sales/send-invite/`
const getInvitedListApi = `${domain}api/v1/dashboard/sales/invited-list/`
const processInviteTokenApi = `${domain}api/v1/dashboard/sales/process-invite/`
const inviteSendProfileDataApi = `${domain}api/v1/dashboard/sales/finish-profile-setup/`

export const sendInviteApiRequest = (data) => interceptor(axios).post(sendInviteApi, data)
export const getInvitedListApiRequest = () => interceptor(axios).get(getInvitedListApi)
export const processInviteTokenApiRequest = (data) => interceptor(axios).post(processInviteTokenApi, data)
export const inviteSendProfileDataApiRequest = (data) => interceptor(axios).post(inviteSendProfileDataApi, data)
