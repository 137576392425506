export function trimValues(obj) {
  const trimmed = {}
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'string') {
      trimmed[key] = obj[key].trim()
    } else if (typeof obj[key] === 'boolean') {
      trimmed[key] = obj[key]
    }
  })
  return trimmed
}
