export const LOWER_CASE_FILTER = 'lowercase'

const filterHandlersRegister = {}

const emptyFilter = (value) => value
const lowercaseFilter = (value) => {
  return value.toLowerCase()
}
filterHandlersRegister[LOWER_CASE_FILTER] = lowercaseFilter

export const getFilterHandler = (filter) => {
  const handler = filterHandlersRegister[filter]
  if (handler === undefined) {
    return emptyFilter
  }

  return handler
}
