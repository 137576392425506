<template>
  <Form
    v-slot="{errors, meta, values, resetForm}"
    @invalid-submit="onInvalidSubmit"
    :initial-values="initialValue"
    ref="singleForm"
  >
    <div :class="'row ' + formClass">
      <div
        v-for="(item, key) in schema"
        :key="key"
        :class="[item.cols, 'mb-2', item.fieldClass]"
      >
        <template v-if="item.customHtml"><div v-html="item.customHtml"></div></template>
        <template v-else>
          <!--common input label-->
          <label
            v-if="item.type !== 'checkbox' && item.type !== 'radio'"
            :for="item.name"
            :class="item.labelClass"
          >{{ item.label }}</label>
          <Field
            v-if="item.type !== 'checkbox'"
            :as="item.as"
            :id="item.name"
            :name="item.name"
            v-bind="item.attrs"
            :key="$i18n.locale"
            :type="item.type"
            v-model="values[item.name]"
            :rules="item.rules"
            :validate-On-Mount="item.validateOnMount"
            :validateOnInput="typeof item.validateOnInput !== 'undefined' ? item.validateOnInput : true"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
            @change="() => {changHandler(item, values)}"
            @input="() => {inputHandler(item, values)}"
            :class="[item.inputClass, errors && errors[item.name] ? 'invalid' : '']"
          >
            <template v-if="item.children && !!item.children.length">
              <component v-for="(child, idx) in item.children"
                        :key="idx"
                        :is="item.tag || child.tag"
                        :settings="child.settings"
                        :title="item.groupTitle"
                        :value="child.value"
                        v-model="item.value"
              >
                {{ child.text }}
              </component>
            </template>
          </Field>
          <Field
            v-if="item.type === 'checkbox'"
            v-model="values[item.name]"
            @change="() => {changHandler(item, values)}"
            type="checkbox"
            :name="item.name"
            :value="true"
            :unchecked-value="false"
            :key="$i18n.locale"
            :rules="item.rules"
            :validate-On-Mount="item.validateOnMount"
            :validateOnInput="typeof item.validateOnInput !== 'undefined' ? item.validateOnInput : true"
            v-slot="{ field }"
          >
            <input
              type="checkbox"
              v-bind="field"
              :value="true"
              :name="item.name"
              :id="item.name"
            />
            <!--label for checkbox label with html content-->
            <label
              v-if="item.type === 'checkbox' && item.htmlLabel"
              v-html="item.htmlLabel"
              :for="item.name"
              :class="item.labelClass"
            ></label>
            <!--label for checkbox label with plain label-->
            <label
              v-if="(item.type === 'checkbox' || item.type === 'radio') && !item.htmlLabel"
              :for="item.id || item.name"
              :class="item.labelClass"
            >{{ item.label }}</label>
          </Field>
          <ErrorMessage
            :name="item.name"
            class="error-message"
            as="div"/>
          <!--desctiption without html content-->
          <small
            v-if="item.description && !item.isHTMLDescription"
            class="form-text text-muted"
          >{{ item.description }}</small>
          <!--desctiption with html content-->
          <small
            v-if="item.description && item.isHTMLDescription"
            class="form-text text-muted"
            v-html="typeof item.description === 'object' ? item.description.value : item.description"
          ></small>
        </template>
      </div>
      <slot name="error"></slot>
    </div>
    <div :class="['row', buttonData.wrapper]">
      <div :class="resetButton ? 'col col-6' : 'col col-auto'">
        <button
          :disabled="(submitAlwaysEnabled && isDisabled) || (!submitAlwaysEnabled && (meta.valid === false || isDisabled || (!meta.dirty && !needPrevalidation)))"
          :class="buttonData.class"
        ><div class="spinner-border spinner-border-sm mr-1" v-if="isDisabled === true"></div>{{ buttonData.text }}</button>
      </div>
      <div class="col col-6" v-if="resetButton">
        <div :class="resetButton.wrapper">
          <modal-wrapper>
            <template v-slot="{ close }">
              <confirmation-modal
                :close="close"
                :content="$t('confirmationResetForm')"
                @confirm="() => {clearForm(resetForm)}"
              ></confirmation-modal>
            </template>
            <template v-slot:trigger="{ open }">
              <button
                @click.prevent="open"
                :class="resetButton.class"
                :disabled="resetDisabled"
              >{{ resetButton.text }}</button>
            </template>
          </modal-wrapper>
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { defineAsyncComponent, toRef } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { useModal } from '@/application/composables/modal'
import { useI18n } from 'vue-i18n'
import { getFilterHandler } from '@/application/composables/filters'

export default {
  name: 'DynamicForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
  },
  data() {
    return {
      value: true,
    }
  },
  props: {
    schema: {
      type: Object,
      required: true,
    },
    buttonData: {
      type: Object,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    formClass: {
      type: String,
      required: false,
    },
    initialFieldValue: {
      type: Object,
      required: false,
    },
    cleanFieldValue: {
      type: Object,
      required: false,
    },
    onChangeHandler: {
      type: Function,
      required: false,
    },
    onInputHandler: {
      type: Function,
      required: false,
    },
    resetButton: {
      type: Object,
      required: false,
    },
    needPrevalidation: {
      type: Boolean,
      required: false,
    },
    resetDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    submitAlwaysEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { openModal } = useModal()
    const i18n = useI18n()
    const initialValue = toRef(props, 'initialFieldValue')
    const changHandler = props.onChangeHandler ? (item, value) => {
      if (!item.tag || item.tag.name !== 'RadioWrapper') {
        const isArray = Array.isArray(props.schema)
        const fieldSettings = isArray ? props.schema.find(field => field.name === item.name) : props.schema[item.name]
        const passedFilter = fieldSettings && fieldSettings.filter
        if (passedFilter !== undefined) {
          const filterHandler = getFilterHandler(passedFilter)
          value[item.name] = filterHandler(value[item.name])
          props.onChangeHandler(item.name, value)
        } else {
          props.onChangeHandler(item.name, value)
        }
      }
    } : () => {}

    const inputHandler = props.onInputHandler ? (item, value) => {
      props.onInputHandler(item.name, value)
    } : () => {}

    return {
      initialValue,
      changHandler,
      inputHandler,
      type: initialValue.value && initialValue.value.type ? initialValue.value.type : null,
      onInvalidSubmit({ errors }) {
        openModal(i18n.t('failed'), Object.values(errors).join('<br>'))
      },
    }
  },
  methods: {
    clearForm(resetForm) {
      const additional = {}
      if (this.cleanFieldValue.type) {
        additional.type = this.type
      }
      this.resetButton.clearStorage()
      resetForm()
      this.$refs.singleForm.setValues({ ...this.cleanFieldValue, ...additional })
    },
  },
}
</script>
