<template>
  <div class="form-group mb-3">
    <DynamicForm
      :schema="inviteSendFormSchema"
      :button-data="inviteSubmitButton"
      :is-disabled="isButtonDisabled"
      form-class="justify-content-center"
      @submit="inviteSubmitHandler"
    >
      <template v-slot:error>
        <div
          class="server-error-message content mt-3"
          v-if="inviteErrorMessage.length"
        >
          {{ inviteErrorMessage }}
          <button
            @click.prevent="inviteErrorMessage = ''"
            class="feather icon-x button"
          ></button>
        </div>
      </template>
    </DynamicForm>
  </div>
</template>

<script>
import DynamicForm from './DynamicForm.vue'
import { useInviteSendForm } from '@/application/composables/inviteForm.js'

export default {
  name: 'InviteSendForm',
  components: {
    DynamicForm,
  },
  setup() {
    const {
      formSchema: inviteSendFormSchema,
      submitButton: inviteSubmitButton,
      submit: inviteSubmitHandler,
      isDisabled: isButtonDisabled,
      errorMessage: inviteErrorMessage,
    } = useInviteSendForm()

    return {
      inviteSendFormSchema,
      inviteSubmitButton,
      inviteSubmitHandler,
      isButtonDisabled,
      inviteErrorMessage,
    }
  },
}
</script>
